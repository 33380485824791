/* eslint-disable no-undef */
export const showItemAddedPopup = (response) => {
	$('#product-added-pop').remove();
	let title = response.panier.produits.reverse()[0].nom;
	const nbProducts = response.panier.produits.length;
	if (nbProducts > 1) {
		title += ` et ${nbProducts - 1}${nbProducts > 2 ? ' autres articles' : ' autre article'}`;
	}

	const popUp = $(`
						<div class="header-search-modal" id="product-added-pop">
						<button class="card-close sidebar-close"><i class="fas fa-times"></i></button>
						<div class="header-search-wrap">
							<div class="card-body">
								<div style="display:flex; justify-content: center; align-items: center">
									<div align="center">
										<div class="mb-4">
											<i class="far fa-check-circle text-success" style="font-size: 120px"></i>
										</div>
										<p class="h4">L'article a bien été ajouté à votre panier.</p>
									</div>
								</div>
								<div class="psearch-results mt-5">
									<div class="axil-product-list mb-5">
										<div class="thumbnail">
											<i class="fal fa-shopping-cart" style="font-size: 48px"></i>
										</div>
										<div class="product-content">
											<span class="product-title h5">${title}</span>
											<div class="product-price-variant">
												<span class="price text-muted">Sous-total : &nbsp;</span>
												<span class="price current-price">${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF', minimumFractionDigits: 0 }).format(response.panier.sous_total)}</span>
											</div>
										</div>
									</div>
									<div class="mt-5 pt-3 d-flex justify-content-center">
										<a href="/paniers/${response.panier.id}" class="me-4 axil-btn btn-bg-primary viewcart-btn">
											<i class="fal fa-shopping-cart text-white"></i>Voir le panier
										</a>
										<a href="/paniers/${response.panier.id}/checkout" class="axil-btn btn-bg-secondary checkout-btn">
											<i class="fad fa-bags-shopping"></i>
											Passer à la caisse
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>`);

	const wrapp = $('body');
	const wrapMask = $('<div / >').addClass('closeMask');
	// cartDropdown = $(openElement);
	wrapp.append(popUp);
	wrapp.addClass('open');
	popUp.addClass('open');
	popUp.parent().append(wrapMask);
	wrapp.css({
		overflow: 'hidden',
	});

	function removeSideMenu() {
		wrapp.removeAttr('style');
		wrapp.removeClass('open').find('.closeMask').remove();
		popUp.removeClass('open');
		popUp.remove();
		wrapMask.remove();
	}

	popUp.find('.sidebar-close, .close-mask').on('click', () => {
		removeSideMenu();
	});

	$.get(`/paniers/${response.panier.id}`, { mode: 'preview' }, (res) => {
		const html = $($(res).find('.cart-body').html());
		html.find('.pro-qty').prepend('<span class="dec qtybtn">-</span>');
		html.find('.pro-qty').append('<span class="inc qtybtn">+</span>');
		$('#cart-dropdown .cart-body').html(html);
	});
	if ($('.shopping-cart .cart-count').length) {
		$('.shopping-cart .cart-count').text(response.panier.produits.length);
	} else $('.shopping-cart .cart-dropdown-btn').prepend(`<span class="cart-count">${response.panier.produits.length}</span>`);
};

export const cartController = (() => {
	const handleAddItem = () => {
		$(document).on('click', '.select-option .add-to-cart', (e) => {
			const $this = $(e.currentTarget);
			const url = $this.attr('href');
			const data = { quantite: 1, produit_id: $this.data('id') };
			$.ajax({
				url,
				type: 'POST',
				data,
				dataType: 'JSON',
				success(response) {
					// window.location.reload();
					showItemAddedPopup(response);
				},
				error() {
					// eslint-disable-next-line no-alert
					alert("Une erreur s'est produite. Veuillez rafraîchir la page et réessayer.");
				},
			});
			return false;
		});
	};

	const update = () => {
		$('.update-cart').on('change', (e) => {
			e.preventDefault();
			const $this = $(e.currentTarget);
			const payload = { _method: 'PATCH', ...$this.data('payload') };
			// eslint-disable-next-line no-undef
			const url = cartUpdateUrl ?? window.location.href;
			$.ajax({
				url,
				type: 'POST',
				dataType: 'json',
				data: payload,
				success(data) {
					if (!$this.data('reload') === false) window.location.reload();
					else {
						// eslint-disable-next-line no-underscore-dangle
						$('.__order-total').text(data._montants.total);
						// eslint-disable-next-line no-underscore-dangle
						$('.__order-taxes').text(data._montants.taxes);
						// eslint-disable-next-line no-underscore-dangle
						$('.__order-delivery-fees').text(data._montants.frais_livraison);
					}
				},
				error() {
					// eslint-disable-next-line no-alert
					alert("Une erreur s'est produite. Veuillez rafraîchir la page et réessayer.");
				},
			});
		});
	};

	const handleRemoveItem = () => {
		$(document).on('click', '.remove-item-from-cart', (e) => {
			const $this = $(e.currentTarget);
			const url = $this.data('uri');
			$.ajax({
				url,
				type: 'POST',
				data: { _method: 'DELETE' },
				dataType: 'JSON',
				beforeSend() {
					$this.closest('.cart-item').fadeOut('slow');
				},
				success(response) {
					$this.closest('.cart-item').remove();
					$('.subtotal-amount').text(response.total);
					$('.cart-count').text(response.count);
					if (response.count === 0) {
						$('.cart-body').html(`<div class="cart-empty">
						<div class="h3 cart-empty-title">Votre panier est vide.</div>
						<p class="cart-empty-subtitle my-0">Vous n'avez pas encore ajouté de produit à votre panier.</p>
					</div>`);
					}
				},
				error() {
					$this.closest('.cart-item').fadeIn('slow');
					// eslint-disable-next-line no-alert
					alert("Une erreur s'est produite. Veuillez rafraîchir la page et réessayer.");
				},
			});
			return false;
		});
	};

	const handleQuantityChange = () => {
		$(document).on('input', '.quantity-input', (e) => {
			const $this = $(e.currentTarget);
			const url = $this.data('uri');
			const data = { _method: 'PATCH', quantite: parseInt($this.val(), 10) };
			$.ajax({
				url,
				type: 'POST',
				data,
				dataType: 'JSON',
				success(response) {
					$('.subtotal-amount').text(response.total);
					$('.cart-count').text(response.count);
					$this.closest('.cart-item').find('.item-price').text(response.item_total);
				},
				error() {
					// eslint-disable-next-line no-alert
					alert("Une erreur s'est produite. Veuillez rafraîchir la page et réessayer.");
				},
			});
			return false;
		});
	};

	const handleEmptyCart = () => {
		$(document).on('click', '.empty-cart, .cart-clear', (e) => {
			const $this = $(e.currentTarget);
			const url = $this.data('uri');
			// eslint-disable-next-line no-restricted-globals, no-alert
			if (!confirm('Êtes-vous sûr de vouloir vider votre panier ?')) return false;
			$.ajax({
				url,
				type: 'POST',
				data: { _method: 'DELETE' },
				dataType: 'JSON',
				success() {
					$('.cart-body').html(`<div class="cart-empty">
						<div class="h3 cart-empty-title">Votre panier est vide.</div>
						<p class="cart-empty-subtitle my-0">Vous n'avez pas encore ajouté de produit à votre panier.</p>
					</div>`);
					$('.subtotal-amount').text('').hide();
					$('.cart-count').text('0').hide();
				},
				error() {
					// eslint-disable-next-line no-alert
					alert("Une erreur s'est produite. Veuillez rafraîchir la page et réessayer.");
				},
			});
			return false;
		});
	};

	return {
		init: () => {
			handleAddItem();
			handleQuantityChange();
			handleRemoveItem();
			handleEmptyCart();
			update();
		},
	};
})();
